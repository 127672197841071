import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {selectCurrentFeUser} from '../../../features/feusers';

import KaiserbergLogo from './KaiserbergLogo';

const Header = () => {
  const dispatch = useDispatch();

  const currentFeUser = useSelector(state => {
    return selectCurrentFeUser(state);
  });

  const isAdministrator = currentFeUser?.usergroup?.includes('1');

  const logout = () => {
    dispatch({type: 'feusers/logout'});
  };

  return (
      <header className="p-3 mb-3 border-bottom fixed-top bg-white shadow-sm">
        <div className="container">
          <div
              className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <div
                className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none pe-4">
              <KaiserbergLogo/>
            </div>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">

              <li>
                <NavLink to="/jobCalendar"
                         className="px-2 btn btn-outline-secondary">Job-Kalender</NavLink>
              </li>

              <li  className="mx-2">
                <NavLink to="/employeeCalendar"
                         className="px-2 btn btn-outline-secondary">Mitarbeiter-Kalender</NavLink>
              </li>

              {isAdministrator && (<>
                    <li>
                      <NavLink to="/userList"
                               className="nav-link px-2 link-dark">Mitarbeiter</NavLink>
                    </li>
                    <li>
                      <NavLink to="/customerList"
                               className="nav-link px-2 link-dark">Kunden</NavLink>
                    </li>

                    <li>
                      <NavLink to="/jobList"
                               className="nav-link px-2 link-dark">Jobs</NavLink>
                    </li>

                    <li>
                      <NavLink to="/holidayList"
                               className="nav-link px-2 link-dark">Feiertage</NavLink>
                    </li>
                  </>
              )}

            </ul>

            <ul className="nav col-12 col-lg-auto mb-3 mb-lg-0 justify-content-center">
              <li className="d-flex align-items-center pe-4 text-secondary">
                Angemeldet als&nbsp;
                <span className="text-dark">{currentFeUser?.username}</span>
              </li>
              <li>
                <a onClick={logout}
                   className="btn btn-outline-secondary">Logout</a>
              </li>
            </ul>

          </div>
        </div>


      </header>
  );
};

export default Header;
