import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UserList from '../../components/user/user-list';

const UserListPage = () => {
  return (
      <Container>
        <Row>
          <Col>
        <h1 className="mt-3">Mitarbeiter</h1>
        <UserList />
          </Col>
        </Row>
      </Container>
  );
};

export default UserListPage;
