import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {BuildingAdd as BuildingAddIcon} from 'react-bootstrap-icons';
import {addCustomer} from '../../features/customers';

const CreateCustomer = (props) => {
  const dispatch = useDispatch();

  const [isSubmitted, setSubmitted] = useState(false);

  const [customer, setCustomer] = useState({name: '', active: true});

  const reset = () => {
    setSubmitted(false);
    setCustomer({name: ''});
  }

  const save = async () => {

    try {
      await dispatch(addCustomer(customer));

      reset();
    } catch (err) {
    }
    finally {

    }
  };

  return (
      <div className="mt-3 mb-5">
        {isSubmitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={reset}>
                Add
              </button>
            </div>
        ) : (
            <button onClick={save} className="btn btn-success">
              <BuildingAddIcon/> Kunden anlegen
            </button>
        )}

      </div>

  );
}

export default CreateCustomer;
