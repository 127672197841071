import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchCustomers, selectCustomersSorted} from '../../features/customers';

import Table from 'react-bootstrap/Table';

import CustomerListItem from './list-item';
import CreateCustomer from './create-customer';

const CustomerList = () => {
  const dispatch = useDispatch();

  const customers = useSelector(state => {
    return selectCustomersSorted(state);
  });

  const customersStatus = useSelector(
      state => { return state.customers.status; });

  useEffect(() => {
    if (customersStatus === 'idle') {
      dispatch(fetchCustomers());
    }
    dispatch({type: 'customers/sortByName'});
  }, [customersStatus, dispatch]);

  return (
      <div>

        <CreateCustomer/>

        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Farbe</th>
            <th className="col-1">Nummer</th>
            <th>Name</th>
            <th className="col-1">Kurz</th>
            <th className="col-1">Aktiv</th>
            <th className="col-3"></th>
          </tr>
          </thead>
          <tbody>
          {customers &&
              customers.map((customer, index) => (
                  <CustomerListItem key={customer.uid} customer={customer}/>
              ))
          }
          </tbody>
        </Table>
      </div>
  );
};

export default CustomerList;
