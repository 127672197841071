import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchUsers, filterUsersByDepartment} from '../../features/users';

import Card from 'react-bootstrap/Card';

import UserCalendarLine from './user-calendar-line';
import {selectAccordionState} from '../../features/ui';

const DepartmentSection = (props) => {
  const dispatch = useDispatch();

  const department = props.department;

  const users = filterUsersByDepartment(useSelector(state => {
    return state.users.items;
  }), department.uid);

  const usersStatus = useSelector(
      state => {return state.users.status;});

  const [isOpen, setOpen] = useState(useSelector(state => {
    return selectAccordionState(state, 'department__' + department.uid);
  }));

//  setOpen();

  const toggleOpen = () => {
    setOpen(!isOpen);
    dispatch({type: 'ui/setAccordionState', payload: {id: 'department__' + department.uid, value: !isOpen}});
  };

  useEffect(() => {
    if (usersStatus === 'idle' && users.length === 0) {
      dispatch(fetchUsers());
    }
  }, [usersStatus]);

  return (
      <Card className="mx-3 my-3">
        <Card.Header onClick={toggleOpen}>{department.title}</Card.Header>

        {isOpen && (
            <Card.Body>
              {users.map((user, index) => (
                  <UserCalendarLine key={index} user={user} />
              ))}
            </Card.Body>
        )}
      </Card>
  );
};

export default DepartmentSection;
