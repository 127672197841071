import {useSelector, useDispatch} from 'react-redux';

import Globals from '../../constants/Globals';

import {useDrop} from 'react-dnd';

import {Button, Card} from 'react-bootstrap';

import {
  countEffortByUserAndDate,
  filterTasksByUserAndDate,
  selectTasks, sortTasksByDoneAndImportant
} from '../../features/tasks';
import CalendarTask from './calendar-task';
import {selectSettings} from '../../features/settings';

const CalendarDay = (props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);

  const userId = props.user.uid;
  const user = props.user;

  const dateString = props.date.toISOString().split('T')[0];

  const tasks = sortTasksByDoneAndImportant(
      filterTasksByUserAndDate(useSelector(selectTasks), userId, dateString));
  const effortCount = countEffortByUserAndDate(tasks, userId, dateString);

  const taskItems = [];

  for (const task of tasks) {
    taskItems.push(<CalendarTask key={task.uid} task={task} mode="employee"/>);
  }

  const allowedDropEffect = 'any';

  function addTask() {
    dispatch({
      type: 'ui/setModalState',
      payload: {
        name: 'newTask',
        value: true,
        params: {mode: 'new', date: dateString, user: userId}
      }
    });
  }

  function selectBackgroundColor(isActive, canDrop) {
    if (isActive) {
      return 'rgba(0, 255, 0, 0.2)';
    } else if (canDrop) {
      return 'rgba(0, 255, 0, 0.1)';
    } else {
      return '#fff';
    }
  }

  function selectBoxShadow(isOverbooked) {
    if (isOverbooked) {
      return 'inset 0 0 20px rgba(255, 0, 0, 0.2)';
    } else {
      return 'none';
    }
  }

  const [{canDrop, isOver}, drop] = useDrop(
      () => ({
        accept: Globals.ITEM_TYPES.CARD,
        drop: () => ({
          date: dateString,
          userId: userId,
          allowedDropEffect
        }),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        })
      }),
      [allowedDropEffect, dateString, userId]
  );
  const isActive = canDrop && isOver;
  const backgroundColor = selectBackgroundColor(isActive, canDrop);

  const boxShadow = selectBoxShadow(
      (effortCount > (user.workingHoursPerDay + Globals.HOURS_PER_DAY_WARNING_OFFSET) && props.showHoursCount));

  return (
      <Card ref={drop} style={{
        backgroundColor: backgroundColor,
        width: settings.cardWidth + 'px',
        minHeight: '120px',
        paddingBottom: '60px',
        boxShadow: boxShadow
      }}>
        {taskItems}

        {props.showHoursCount ?
            <div style={{
              position: 'absolute',
              left: '1rem',
              bottom: '1rem',
              fontSize: '10px',
              color: 'gray'
            }}>
              Stunden: {effortCount} von {user.workingHoursPerDay}</div> : ''}

        <Button variant="outline-primary" style={{
          position: 'absolute',
          right: '1rem',
          bottom: '1rem',
          width: '40px'
        }} onClick={addTask}>+</Button>
      </Card>
  );
};

export default CalendarDay;
