import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {EmojiSunglasses as EmojiSunglassesIcon} from 'react-bootstrap-icons';
import {addHoliday} from '../../features/holidays';

const CreateHoliday = (props) => {
  const dispatch = useDispatch();

  const [isSubmitted, setSubmitted] = useState(false);

  const [holiday, setHoliday] = useState({name: ''});

  const reset = () => {
    setSubmitted(false);
    setHoliday({name: '', date: ''});
  }

  const save = async () => {

    try {
      await dispatch(addHoliday(holiday));

      reset();
    } catch (err) {
    }
    finally {

    }
  };

  return (
      <div className="mt-3 mb-5">
        {isSubmitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={reset}>
                Add
              </button>
            </div>
        ) : (
            <button onClick={save} className="btn btn-success">
              <EmojiSunglassesIcon/> Feiertag anlegen
            </button>
        )}

      </div>

  );
}

export default CreateHoliday;
