const KaiserbergLogo = () => {
  return (
    <div className="kaiserberg-logo mb-3 mx-auto text-center" style={{width: '200px'}}>
      <img src="/kaiserberg-logo.svg" alt="Kaiserberg Logo" width="100%"/>
      <h5>PROJECTS</h5>
    </div>
  );
}

export default KaiserbergLogo;
