import React from 'react';
import {useSelector} from 'react-redux';

import {selectSettings} from '../../features/settings';
import {isHolidayOrWeekend} from '../../features/holidays';

import Card from 'react-bootstrap/Card';
import {selectJobById} from '../../features/jobs';

const CalendarDateLine = (props) => {

  const getNumberOfWeek = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const settings = useSelector(selectSettings);

  const startDate = settings.date;
  const days = settings.displayDurationWeeks * 7;

  const holidays = useSelector(state => {
    return state.holidays.items;
  });

  const dayItems = [];

  for (let i = 0; i < days; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    const formattedDate = date.toLocaleDateString('de-DE', {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric'
    });

    /* add calendar week number on first day of week */

    let calendarWeek = '';
    if (date.getDay() === 1) {
      calendarWeek = <strong>KW {getNumberOfWeek(date)}</strong>;
    }

    const isHoliday = isHolidayOrWeekend(holidays, date);

    const opacity = isHoliday ? 0.5 : 1;
    const backgroundColor = isHoliday ? 'var(--bs-card-cap-bg)' : 'white';

    const dayItem = <Card key={i} style={{
      width: settings.cardWidth + 'px',
      backgroundColor: backgroundColor,
      opacity: opacity,
      borderBottom: 'none',
    }}>
      <div className="m-2">
        {calendarWeek} {formattedDate}
      </div>
    </Card>;
    dayItems.push(dayItem);
  }

  return (
      <div>
        <div className="card-group"
             style={{paddingLeft: '35px', marginRight: '33px'}}>
          <div style={{width: settings.cardWidth + 'px', background: 'none', flex: '1 0'}}></div>
          <div style={{width: settings.cardWidth + 'px', background: 'none', flex: '1 0'}}></div>
          {dayItems}
        </div>
      </div>
  );
};

export default CalendarDateLine;
