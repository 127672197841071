import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

const apiGetAllUsers = () => {
  return http.get('/user/all');
};

const apiCreateUser = (data) => {
  return http.post('/user', data);
};

const apiUpdateUser = (uid, data) => {
  return http.put(`/user/${uid}`, data);
};

const apiDeleteUser = (uid) => {
  return http.delete(`/user/${uid}`);
};

export const fetchUsers = createAsyncThunk(
    'users/FetchUsers',
    async (_, thunkAPI) => {
      const result = await apiGetAllUsers();
      return result.data;
    }
);

export const addUser = createAsyncThunk(
    'users/add',
    async (user, thunkAPI) => {
      const response = await apiCreateUser(user);
      thunkAPI.dispatch(fetchUsers());
      return response.data;
    }
);

export const updateUser = createAsyncThunk(
    'users/update',
    async (user, thunkAPI) => {
      const response = await apiUpdateUser(user.uid, user);
      thunkAPI.dispatch(fetchUsers());
      return response.data;
    }
);

export const removeUser = createAsyncThunk(
    'users/remove',
    async (user, thunkAPI) => {
      const response = await apiDeleteUser(user.uid);
      thunkAPI.dispatch(fetchUsers());
      return response.data;
    }
);

const sortByName = (state, action) => {
  if (!Array.isArray(state.items)) {
    return state;
  }

  state.itemsSorted = state.items.toSorted((a, b) => {
    return a.name.localeCompare(b.name);
  });
}

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    items: [],
    itemsSorted: [],
    status: 'idle',
    error: null
  },
  reducers: {
    sortByName: (state, action) => {
      sortByName(state, action);
      return state;
    },
  },
  extraReducers(builder) {
    builder
        .addCase(fetchUsers.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchUsers.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchUsers.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(addUser.fulfilled, (state, action) => {
        })
        .addCase(updateUser.fulfilled, (state, action) => {
        })
        .addCase(removeUser.fulfilled, (state, action) => {
        });
  }
});

export default usersSlice.reducer;

export const selectUsers = (state) => {
  return state.users.items;
};

export const selectUsersSorted = (state) => {
  return state.users.itemsSorted;
};

export const selectUsersByDepartment = (state, departmentUid) => {
  const users = state.users.items;
  if (Array.isArray(users)) {
    return users.filter((user) => {
      if (!user.department) {
        return false;
      }
      return user.department.uid === departmentUid
    });
  }
  return [];
}

export const filterUsersByDepartment = (users, departmentUid) => {
  return users.filter((user) => {
    if (!user.department) {
      return false;
    }
    return user.department === departmentUid
  });
}

export const selectUserById = (state, uid) => {
  return state.users.items.find((user) => user.uid === uid);
};


export const sortUsersByLastName = (users) => {
  if (!Array.isArray(users) || users.length === 0) {
    return [];
  }

  return [...users].sort((a, b) => {
    return a.name.split(' ').pop().localeCompare(b.name.split(' ').pop());
  });
}

