import EmployeeCalendar
  from '../../components/employee-calendar/employee-calendar';

import NewTaskModal from '../../components/task/new-task-modal';

const EmployeeCalendarPage = () => {
  return (
      <div>
        <EmployeeCalendar/>
        <NewTaskModal/>
      </div>
  );
};

export default EmployeeCalendarPage;
