import {Button, ButtonGroup, Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Save as SaveIcon} from 'react-bootstrap-icons';
import {updateHoliday, removeHoliday} from '../../features/holidays';

const HolidayListItem = (props) => {
  const dispatch = useDispatch();

  const [holiday, setHoliday] = useState(props.holiday);

  const [isEdit, setEdit] = useState(() => {
    return (holiday.title === '') || false;
  });

  const toggleEdit = () => {
    setEdit(!isEdit);
  };

  const save = async () => {
    try {
      await dispatch(updateHoliday(holiday));
      setEdit(false);
    } catch (err) {

    } finally {

    }
  };

  const remove = async () => {
    try {
      await dispatch(removeHoliday(holiday));
    } catch (err) {}
  };

  const reset = () => {
    setHoliday(props.holiday);
    setEdit(false);
  };

  useEffect(() => {
    setHoliday(props.holiday);
  }, [props.holiday]);

  let output;

  if (isEdit) {
    output = (
        <tr>
          <td className="align-middle">
            <Form.Control autoFocus type="text" placeholder=""
                          value={holiday.title}
                          onChange={(e) => setHoliday(
                              {...holiday, title: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Control
                className="mx-2"
                type="date"
                id="dateSelector"
                value={holiday.date}
                onChange={(e) => setHoliday(
                    {...holiday, date: e.target.value})}
                title="Datum wählen"/>
          </td>


          <td className="text-end align-middle" style={{width: '340px'}}>
            <ButtonGroup className="">
              <Button onClick={save}><SaveIcon/> speichern</Button>
              <Button onClick={remove}>löschen</Button>
              <Button onClick={reset}>abbrechen</Button>
            </ButtonGroup>
          </td>

        </tr>
    )
    ;
  } else {

    const formattedDate = new Date(holiday.date).toLocaleDateString('de-DE', {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });

    output = (


        <tr>
          <td className="align-middle px-3">{holiday.title}</td>
          <td className="align-middle px-3">{formattedDate}</td>
          <td className="align-middle text-end">
            <Button onClick={toggleEdit}>bearbeiten</Button></td>
        </tr>
    );
  }

  return output;
};

export default HolidayListItem;
