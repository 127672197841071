import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchUsers, filterUsersByDepartment} from '../../features/users';

import Card from 'react-bootstrap/Card';

import JobCalendarLine from './job-calendar-line';
import {selectAccordionState} from '../../features/ui';
import {fetchJobs, filterJobsByCustomer} from '../../features/jobs';

const CustomerSection = (props) => {
  const dispatch = useDispatch();

  const customer = props.customer;

  const jobsStatus = useSelector(
      state => {return state.jobs.status;});

  const jobs = filterJobsByCustomer(useSelector(state => {
    return state.jobs.items;
  }), customer.uid);

  const [isOpen, setOpen] = useState(useSelector(state => {
    return selectAccordionState(state, 'customer__' + customer.uid);
  }));

  const toggleOpen = () => {
    setOpen(!isOpen);
    dispatch({type: 'ui/setAccordionState', payload: {id: 'customer__' + customer.uid, value: !isOpen}});
  };

  useEffect(() => {
    if (jobsStatus === 'idle') {
      dispatch(fetchJobs());
    }
  }, [jobsStatus]);

  return (
      <Card className="mx-3 my-3">
        <Card.Header onClick={toggleOpen}>{customer.title}</Card.Header>

        {isOpen && (
            <Card.Body>
              {jobs.map((job, index) => (
                  <JobCalendarLine key={index} job={job} />
              ))}
            </Card.Body>
        )}
      </Card>
  );
};

export default CustomerSection;
