const KaiserbergLogo = () => {
  return (
    <div className="kaiserberg-logo d-flex ">
      <img src="/kaiserberg-logo.svg" alt="Kaiserberg Logo" style={{width: '100px'}}/>
      <h6 style={{margin: '2px 0 0 5px'}}>PROJECTS</h6>
    </div>
  );
}

export default KaiserbergLogo;
