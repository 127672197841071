import {configureStore, createStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {
  persistStore, persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import feusers from './features/feusers';
import departments from './features/departments';
import users from './features/users';
import customers from './features/customers';
import tasks from './features/tasks';
import jobs from './features/jobs';
import holidays from './features/holidays';
import settings from './features/settings';
import ui from './features/ui';

const reducers = combineReducers({
  feusers: feusers,
  departments: departments,
  users: users,
  customers: customers,
  tasks: tasks,
  jobs: jobs,
  holidays: holidays,
  settings: settings,
  ui: ui
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['ui']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
});
