import {useSelector, useDispatch} from 'react-redux';

import {fetchJobs, selectJobs} from '../../features/jobs';
import {
  addTask,
  updateTask,
  selectTaskByUid,
  removeTask,
  emptyTask
} from '../../features/tasks';

import {selectModalState} from '../../features/ui';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Pencil as PencilIcon, Eye as EyeIcon} from 'react-bootstrap-icons';

import Select from 'react-select';
import {useEffect, useState} from 'react';
import {selectCustomers} from '../../features/customers';
import {
  sortUsersByLastName
} from '../../features/users';

const convertUrlsToLinks = function(text) {
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
  return text.replace(urlRegex, url => `<a href="${url.startsWith('www.')
      ? 'https://' + url
      : url}" target="_blank" rel="noreferrer">${url}</a>`);
};

const convertNLToBr = function(text) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};

const NewTaskModal = (props) => {
  const dispatch = useDispatch();

  const modalState = useSelector((state) => selectModalState(state, 'newTask'));
  const show = modalState.state;

  const existTask = useSelector(
      (state) => selectTaskByUid(state, modalState?.values?.taskUid));

  const [task, setTask] = useState({...emptyTask});

//  const [validated, setValidated] = useState(false);

  const [descriptionEditable, setDescriptionEditable] = useState(false);

  const jobs = useSelector(state => {
    return selectJobs(state);
  });

  const customers = useSelector(state => {
    return selectCustomers(state);
  });

  const jobOptions = [];
  if (jobs) {
    jobs.forEach((job) => {

      const customer = customers.find((customer) => {
        return customer.uid === job.customer;
      });

      jobOptions.push(
          {
            value: job.uid,
            label: job.title + ' ' + job.description + ' (' +
                customer?.shortTitle + ')'
          });
    });
  }

  const jobsStatus = useSelector(
      state => { return state.jobs.status; });

  const users = sortUsersByLastName(useSelector(state => {
    return state.users.items
  }));

  const userOptions = [];
  if (users) {
    users.forEach((user) => {
      userOptions.push(
          {
            value: user.uid,
            label: user.name
          });
    });
  }

  useEffect(() => {
    if (jobsStatus === 'idle') {
      dispatch(fetchJobs());
    }

    if (existTask) {
      setTask({...existTask});
    } else {
      setTask({...emptyTask, ...{user: modalState.values?.user, job: modalState.values?.job}});
    }

    if (task.description && task.description !== '') {
      setDescriptionEditable(false);
    }

  }, [jobsStatus, existTask, modalState]);

  const effortValues = [
    {value: 0, label: 'ohne'},
    {value: 0.25, label: '0,25 Std.'},
    {value: 0.5, label: '0,5 Std.'},
    {value: 0.75, label: '0,75 Std.'},
    {value: 1, label: '1 Std.'},
    {value: 1.5, label: '1,5 Std.'},
    {value: 2, label: '2 Std.'},
    {value: 2.5, label: '2,5 Std.'},
    {value: 3, label: '3 Std.'},
    {value: 3.5, label: '3,5 Std.'},
    {value: 4, label: '4 Std.'},
    {value: 4.5, label: '4,5 Std.'},
    {value: 5, label: '5 Std.'},
    {value: 6, label: '6 Std.'},
    {value: 7, label: '7 Std.'},
    {value: 8, label: '8 Std.'},
    {value: 9, label: '9 Std.'},
    {value: 10, label: '10 Std.'}
  ];

  const importantValues = [
    {value: 0, label: 'niedrig'},
    {value: 1, label: 'normal'},
    {value: 2, label: 'hoch'},
    {value: 3, label: '!@#$☠!'}
  ];

  const closeModal = () => {
    dispatch({
      type: 'ui/setModalState',
      payload: {name: 'newTask', value: false, params: {}}
    });
  };

  const saveTask = async () => {
    const taskToSave = {
      ...task,
//      user: modalState.values.user,
      date: modalState.values.date
    };
    try {
      if (task.uid) {
        await dispatch(updateTask(taskToSave));
      } else {
        await dispatch(addTask(taskToSave));
      }

      closeModal();
    } catch (err) {
    } finally {

    }
  };

  const remove = async () => {
    try {
      await dispatch(removeTask(task));
      closeModal();
    } catch (err) {
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
    }

//    setValidated(true);
    saveTask();
  };

  let doneCheckbox = '';

  if (task.uid) {
    doneCheckbox = (
        <Form.Group className="mb-3" controlId="newsTaskForm.done">
          <Form.Check type="switch" variant="" label="erledigt" checked={task?.done || 0}
                      onChange={(e) => setTask(
                          {...task, done: e.target.checked ? 1 : 0})}/>
        </Form.Group>
    );
  }

  let modalTitle = 'Neue Aufgabe';
  if (task.uid) {
    modalTitle = task.teaser || 'Aufgabe bearbeiten';
  }

  return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            {doneCheckbox}

            <Form.Group className="mb-3 " controlId="newsTaskForm.teaser">
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control
                  as="input"
                  placeholder=""
                  autoFocus
                  value={task.teaser}
                  onChange={(e) => setTask({...task, teaser: e.target.value})}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="newsTaskForm.job">
              <Form.Label>Job</Form.Label>
              <Select options={jobOptions} required name="job"
                      placeholder="bitte auswählen"
                      value={jobOptions.find(
                          option => option.value === task.job)}
                      onChange={(value) => {
                        setTask({...task, job: value.value});
                      }}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="newsTaskForm.user">
              <Form.Label>Mitarbeiter</Form.Label>
              <Select options={userOptions} required name="user"
                      placeholder="bitte auswählen"
                      value={userOptions.find(
                          option => option.value === task.user)}
                      onChange={(value) => {
                        setTask({...task, user: value.value});
                      }}/>
            </Form.Group>

            <div className="row">

              <div className="col">
                <Form.Group className="mb-3" controlId="newsTaskForm.effort">
                  <Form.Label>Aufwand</Form.Label>
                  <Form.Select aria-label="Aufwand" value={task.effort}
                               onChange={(e) => {
                                 setTask({...task, effort: e.target.value});
                               }}>
                    {effortValues &&
                        effortValues.map((effort, index) => (
                            <option key={index}
                                    value={effort.value}>{effort.label}</option>
                        ))
                    }
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col">
                <Form.Group className="mb-3" controlId="newsTaskForm.important">
                  <Form.Label>Priorität</Form.Label>
                  <Form.Select aria-label="Priorität" value={task.important}
                               onChange={(e) => {
                                 setTask({...task, important: e.target.value});
                               }}>
                    {importantValues &&
                        importantValues.map((important, index) => (
                            <option key={index}
                                    value={important.value}>{important.label}</option>
                        ))
                    }
                  </Form.Select>
                </Form.Group>
              </div>
            </div>


            <Form.Group className="mb-3" controlId="newsTaskForm.description">
              <Form.Label>Infos</Form.Label>

              <div style={{position: 'relative'}}>
                {!descriptionEditable && <div
                    className="form-control"
                    style={{height: '120px', overflowY: 'scroll'}}
                    dangerouslySetInnerHTML={{
                      __html: convertNLToBr(
                          convertUrlsToLinks(task.description))
                    }}></div>}

                {descriptionEditable && <Form.Control
                    as="textarea" rows="4"
                    style={{height: '120px'}}
                    placeholder=""
                    value={task.description}
                    onChange={(e) => setTask(
                        {...task, description: e.target.value})}
                />}

                <Button variant="light" size="sm" style={{position: 'absolute', bottom: '.5rem', right: '.5rem'}}
                        onClick={() => setDescriptionEditable(
                            !descriptionEditable)}>
                  {descriptionEditable ? <EyeIcon/> : <PencilIcon/>}
                </Button>

              </div>


            </Form.Group>

            <Button variant="outline-primary" type="submit"
                    style={{marginRight: '1rem'}}>
              speichern
            </Button>

            <Button variant="outline-secondary" onClick={closeModal}
                    style={{marginRight: '1rem'}}>
              abbrechen
            </Button>

            {task.uid && <Button variant="outline-danger" onClick={remove}>
              löschen
            </Button>}

          </Form>
        </Modal.Body>
      </Modal>
  );
};

export default NewTaskModal;
