import React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist';
import ReactDOM from 'react-dom/client';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store';
import {Provider} from 'react-redux';

import App from './App';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <DndProvider backend={HTML5Backend}>
        <React.StrictMode>
          <App/>
        </React.StrictMode>
      </DndProvider>
      </PersistGate>
    </Provider>
);
