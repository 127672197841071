import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
import {filterUsersByDepartment} from './users';
const http = createAxiosClient();

const apiGetAllJobs = () => {
  return http.get('/job/all');
};

const apiCreateJob = (data) => {
  return http.post('/job', data);
};

const apiUpdateJob = (uid, data) => {
  return http.put(`/job/${uid}`, data);
};

const apiDeleteJob = (uid) => {
  return http.delete(`/job/${uid}`);
};

export const fetchJobs = createAsyncThunk(
    'jobs/FetchJobs',
    async (_, thunkAPI) => {
      const result = await apiGetAllJobs();
      return result.data;
    }
);

export const addJob = createAsyncThunk(
    'jobs/add',
    async (job, thunkAPI) => {
      const response = await apiCreateJob(job);

      thunkAPI.dispatch(fetchJobs());

      return response.data;
    }
);

export const updateJob = createAsyncThunk(
    'jobs/update',
    async (job, thunkAPI) => {
      const response = await apiUpdateJob(job.uid, job);
      thunkAPI.dispatch(fetchJobs());
      return response.data;
    }
);

export const removeJob = createAsyncThunk(
    'jobs/remove',
    async (job, thunkAPI) => {
      const response = await apiDeleteJob(job.uid);
      thunkAPI.dispatch(fetchJobs());
      return response.data;
    }
);

const sortByName = (state, action) => {
  if (!Array.isArray(state.items)) {
    return state;
  }

  state.itemsSorted = state.items.toSorted((a, b) => {
    return a.title.localeCompare(b.title);
  });
}

const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    items: [],
    itemsSorted: [],
    status: 'idle',
    error: null
  },
  reducers: {
    sortByName: (state, action) => {
      sortByName(state, action);
      return state;
    },
  },
  extraReducers(builder) {
    builder
        .addCase(fetchJobs.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchJobs.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchJobs.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(addJob.fulfilled, (state, action) => {
        })
        .addCase(updateJob.fulfilled, (state, action) => {
        })
        .addCase(removeJob.fulfilled, (state, action) => {
        });
  }
});

export default jobsSlice.reducer;

export const selectJobs = (state) => {
  return state.jobs.items;
};

export const selectJobById = (state, uid) => {
  return state.jobs.items.find((job) => job.uid === uid);
};

export const selectJobsSorted = (state) => {
  return state.jobs.itemsSorted;
};

export const filterJobsByCustomer = (jobs, customerUid) => {
  return jobs.filter((job) => job.customer === customerUid);
};
