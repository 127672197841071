import {createSlice} from '@reduxjs/toolkit';

let defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() - defaultDate.getDay() + 1);
defaultDate = defaultDate.toISOString().slice(0, 10);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    settings: {
      date: defaultDate,
      displayDurationWeeks: 4,
      cardWidth: 200
    },
  },
  reducers: {
    set: (state, action) => {
      state.settings = {...state.settings, [action.payload.name]: action.payload.value};
    },
  },
});

export default settingsSlice.reducer;

export const selectSettings = (state) => {
  return state.settings.settings;
};

export const selectSetting = (state, name) => {
  if (state.settings.settings[name]) {
    return state.settings.settings[name];
  }
  return null;
};
