import JobCalendar
  from '../../components/job-calendar/job-calendar';

import NewTaskModal from '../../components/task/new-task-modal';

const JobCalendarPage = () => {
  return (
      <div>
        <JobCalendar/>
        <NewTaskModal/>
      </div>
  );
};

export default JobCalendarPage;
