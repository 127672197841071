import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

const apiGetAllTasks = () => {
  return http.get('/task/all');
};

const apiCreateTask = (data) => {
  return http.post('/task', data);
};

const apiUpdateTask = (uid, data) => {
  return http.put(`/task/${uid}`, data);
};

const apiDeleteTask = (uid) => {
  return http.delete(`/task/${uid}`);
};

export const fetchTasks = createAsyncThunk(
    'tasks/FetchTasks',
    async () => {
      const result = await apiGetAllTasks();
      return result.data;
    }
);

export const addTask = createAsyncThunk(
    'tasks/add',
    async (task) => {
      const response = await apiCreateTask(task);
      return response.data;
    }
);

export const updateTask = createAsyncThunk(
    'tasks/update',
    async (task, thunkAPI) => {
      const response = await apiUpdateTask(task.uid, task);
      await thunkAPI.dispatch(fetchTasks());
      return response.data;
    }
);

export const removeTask = createAsyncThunk(
    'tasks/remove',
    async (task, thunkAPI) => {
      const response = await apiDeleteTask(task.uid);
      thunkAPI.dispatch(fetchTasks());
      return response.data;
    }
);

export const copyTask = createAsyncThunk(
    'tasks/copy',
    async (task) => {
      const response = await apiCreateTask(task);
      return response.data;
    }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    items: [],
    status: 'idle',
    error: null
  },
  reducers: {

    move(state, action) {

      /* copy task */
      let task = {...action.payload.task};

      task.date = action.payload.date;

      if (action.payload.userId) {
        task.user = action.payload.userId;
      }

      if (action.payload.jobId) {
        task.job = action.payload.jobId;
      }

      /* replace task in state */
      const index = state.items.findIndex((item) => item.uid === task.uid);
      state.items[index] = task;

      apiUpdateTask(task.uid, task);
    }

//     update: (state, action) => {
//       const updatedTask = action.payload;
//       const index = state.items.findIndex(  (item) => item.uid === updatedTask.uid);
//       state.items[index] = updatedTask;
//
//       apiUpdateTask(updatedTask.uid, updatedTask);
//     },
  },
  extraReducers(builder) {
    builder
        .addCase(fetchTasks.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchTasks.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchTasks.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(addTask.fulfilled, (state, action) => {
          state.items.push(action.payload);
        })
        .addCase(updateTask.fulfilled, (state, action) => {
        })
        .addCase(copyTask.fulfilled, (state, action) => {
          state.items.push(action.payload);
//          fetchTasks();
        });
  }
});

export default tasksSlice.reducer;

// export const selectTaskByUid = (state, taskUid) => {
// //  console.log('state.tasks.items', state.tasks.items, taskUid, state.tasks.items.length);
//   return state.tasks.items.find((task) => task.uid === taskUid);
// }

export const selectTasks = (state) => {
  return state.tasks.items;
};

export const selectTaskByUid = (state, taskUid) => {
  if (!state.tasks.items) {
    return null;
  }

  return state.tasks.items.find((task) => task.uid === taskUid);
};

export const selectTasksByUserAndDate = (state, userId, date) => {
  return filterTasksByUserAndDate(state.tasks.items, userId, date);
};

export const filterTasksByUserAndDate = (tasks, userId, date) => {
//  console.log(tasks, userId, date);
  if (Array.isArray(tasks)) {
    return tasks.filter((task) => {
      if (!task.user || !task.date) {
        return false;
      }
      return task.user === userId && task.date === date;
    });
  }
  return [];
};

export const countEffortByUserAndDate = (tasks, userId, date) => {
  let count = 0;
  for (const task of tasks) {
    if (task.user === userId && task.date === date) {
      count += parseFloat(task.effort);
    }
  }
  return count;
};

export const sortTasksByDoneAndImportant = (tasks) => {
  if (Array.isArray(tasks)) {
    return tasks.sort((a, b) => {
      if (a.done === b.done) {
        return b.important - a.important;
      }
      return a.done - b.done;
    });
  }
  return [];
}

export const filterTasksByJobAndDate = (tasks, jobId, date) => {
  if (Array.isArray(tasks)) {
    return tasks.filter((task) => {
      if (!task.job || !task.date) {
        return false;
      }
      return task.job === jobId && task.date === date;
    });
  }
  return [];
}

export const emptyTask = {
  teaser: '',
  description: '',
  effort: 0,
  important: 1,
  done: 0,
  job: null,
  user: null
};
