import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {PersonAdd as PersonAddIcon} from 'react-bootstrap-icons';
import {addUser} from '../../features/users';

const CreateUser = (props) => {
  const dispatch = useDispatch();

  const [isSubmitted, setSubmitted] = useState(false);
//  const [name, setName] = useState('');

  const [user, setUser] = useState({name: ''});

  const reset = () => {
    setSubmitted(false);
    setUser({name: ''});
  }

  const save = async () => {

    try {
      await dispatch(addUser(user));

      reset();
    } catch (err) {
    }
    finally {

    }
  };

  return (
      <div className="mt-3 mb-5">
        {isSubmitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={reset}>
                Add
              </button>
            </div>
        ) : (
            <button onClick={save} className="btn btn-success">
              <PersonAddIcon/> Mitarbeiter anlegen
            </button>
        )}

      </div>

  );
}

export default CreateUser;
