import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchUsers, selectUsersSorted, selectUsers} from '../../features/users';

import Table from 'react-bootstrap/Table';

import UserListItem from './list-item';
import CreateUser from './create-user';

const UserList = () => {
  const dispatch = useDispatch();

  const users = useSelector(state => {
    return selectUsersSorted(state);
  });

  const usersStatus = useSelector(
      state => { return state.users.status; });

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
    dispatch({type: 'users/sortByName'});
  }, [usersStatus, dispatch]);

  return (
      <div>

        <CreateUser/>

        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Farbe</th>
            <th>Name</th>
            <th>Kurz</th>
            <th className="col-2">Abteilung</th>
            <th className="col-2">Arbeitsstunden / Tag</th>
            <th>Kommentar</th>
            <th className="col-3"></th>
          </tr>
          </thead>
          <tbody>
          {users &&
              users.map((user, index) => (
                  <UserListItem key={user.uid} user={user}/>
              ))
          }
          </tbody>
        </Table>
      </div>
  );
};

export default UserList;
