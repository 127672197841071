import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Form from 'react-bootstrap/Form';
import {doLogin} from '../../features/feusers';

import KaiserbergLogo from './KaiserbergLogo';

const LoginPage = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const feusersStatus = useSelector(
      state => {return state.feusers.status;});

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(doLogin({username :name, password: password}));
  };

  let alertMessage = ''

  switch(feusersStatus) {
    case 'loading':
      alertMessage = <div className="alert alert-primary" role="alert">
        Lädt ...
      </div>;
      break;
    case 'loggedIn':
      alertMessage = <div className="alert alert-success" role="alert">
        Angemeldet
      </div>;
      break;
    case 'failed':
      alertMessage = <div className="alert alert-danger" role="alert">
        Fehlgeschlagen
      </div>;
      break;
    default:
      break;
  }

  return (
      <div className="container-fluid h-100">

        <div className="row justify-content-center align-items-center h-100">

          <div
              className="col col-sm-6 col-md-6 col-lg-4 col-xl-3 shadow p-4 rounded-3">

            <KaiserbergLogo className="mb-5" />

            {alertMessage}

            <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    as="input"
                    placeholder=""
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <div className="w-100 text-center">
                <button className="btn btn-outline-primary"
                        type="submit">Login
                </button>
              </div>

            </Form>
          </div>

        </div>

      </div>
  );
};

export default LoginPage;
