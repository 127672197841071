import {createSlice} from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    modalStats: {},
    accordionStats: {}
  },
  reducers: {
    setModalState: (state, action) => {
      const modalName = action.payload.name;
      state.modalStats[modalName] = {state: action.payload.value, values: action.payload.params};
    },
    setAccordionState: (state, action) => {
      const accordionId = action.payload.id;
      state.accordionStats[accordionId] = action.payload.value;
    },
  }
});

export default uiSlice.reducer;

export const selectModalState = (state, modalName) => {
  if (!state.ui.modalStats.hasOwnProperty(modalName)) {
    return false;
  }
  return state.ui.modalStats[modalName];
}

export const selectAccordionState = (state, accordionId) => {
  if (!state.ui.accordionStats.hasOwnProperty(accordionId)) {
    return true;
  }
  return state.ui.accordionStats[accordionId];
}
