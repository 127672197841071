import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
  return (
      <div className="container-fluid h-100">

        <div className="row justify-content-center align-items-center h-100">

            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Lädt...</span>
            </Spinner>
        </div>
      </div>
  )
};

export default Loading;
