import {Button, ButtonGroup, Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Save as SaveIcon} from 'react-bootstrap-icons';
import {updateUser, removeUser} from '../../features/users';
import DepartmentSelect from './department-select';

const UserListItem = (props) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(props.user);

  let departmentUid = '';
  if (user.department) {
    departmentUid = user.department;
  }

  const [isEdit, setEdit] = useState(() => {
    return (user.name === '') || false;
  });

  const toggleEdit = () => {
    setEdit(!isEdit);
  };

  const save = async () => {
      try {
        await dispatch(updateUser(user));
        setEdit(false);
      } catch (err) {

      }
      finally {

      }
  };

  const remove = async () => {
    try {
      await dispatch(removeUser(user));
    } catch (err) {

    }

  };

  const reset = () => {
    setUser(props.user);
    setEdit(false);
  }

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  let output;

  if (isEdit) {
    output = (
        <tr>
          <td style={{width: '80px'}} className="align-middle">
            <Form.Control
                type="color"
                id="exampleColorInput"
                value={user.color}
                onChange={(e) => setUser({...user, color: e.target.value})}
                title="Farbe auswählen" style={{width: '40px', height: '40px', padding: 0, border: 'none'}}/>
          </td>
          <td className="align-middle"><Form.Control autoFocus type="text" placeholder=""
                            value={user.name}
                            onChange={(e) => setUser({...user, name: e.target.value})}/></td>
          <td className="align-middle"><Form.Control type="text" placeholder=""
                                                     maxLength="3"
                                                     style={{width: '60px'}}
                                                     value={user.shortName}
                                                     onChange={(e) => setUser({...user, shortName: e.target.value})}/></td>

          <td className="align-middle">
            <DepartmentSelect value={departmentUid} onChange={(e) => setUser({...user, department: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Select value={user.workingHoursPerDay} onChange={(e) => setUser({...user, workingHoursPerDay: e.target.value})}>
              <option value="3">3 Stunden</option>
              <option value="4">4 Stunden</option>
              <option value="5">5 Stunden</option>
              <option value="6">6 Stunden</option>
              <option value="7">7 Stunden</option>
              <option value="8">8 Stunden</option>
            </Form.Select>
          </td>

          <td className="align-middle"><Form.Control autoFocus type="text" placeholder=""
                                                     value={user.description}
                                                     onChange={(e) => setUser({...user, description: e.target.value})}/></td>

          <td className="text-end align-middle" style={{width: '340px'}}>
            <ButtonGroup className="">
              <Button onClick={save}><SaveIcon/> speichern</Button>
              <Button onClick={remove}>löschen</Button>
              <Button onClick={reset}>abbrechen</Button>
            </ButtonGroup>
          </td>

        </tr>
    );
  } else {

    let departmentName = '';
    if (user.department) {
      departmentName = user.department;
    }

    output = (
        <tr>
          <td style={{width: '80px'}} className="align-middle"><div className="rounded" style={{width: '40px', height: '40px', backgroundColor: user.color}}></div></td>
          <td className="align-middle">{user.name}</td>
          <td className="align-middle px-3">{user.shortName}</td>
          <td className="align-middle">{departmentName}</td>
          <td className="align-middle">{user.workingHoursPerDay} Stunden </td>
          <td className="align-middle px-3">{user.description}</td>
          <td className="align-middle text-end" style={{width: '340px'}}>
            <Button onClick={toggleEdit}>bearbeiten</Button></td>
        </tr>
    );
  }

  return output;
};

export default UserListItem;
