import {useDispatch} from 'react-redux';

import axios from 'axios';

import doLogout from './features/feusers';

export const createAxiosClient = () => {
  const client = axios.create({
    baseURL: 'https://projects-backend.kaiserberg.net/api',
    headers: {'Content-Type': 'application/json'}
  });

  client.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('userToken');
        if (token) {
          config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
  );

  client.interceptors.response.use((response) => response, (error) => {

//    console.log('error.response.status', error.response.status);

    if ([401, 403].indexOf(error.response.status) !== -1) {
//      console.log('do error handling');

      localStorage.removeItem('userToken');
      window.location.reload();
    }
    return Promise.reject(error);
  });

  return client;
};

export default {};
