import React from 'react';
import {useSelector} from 'react-redux';

import {selectSettings} from '../../features/settings';

import CalendarDay from './calendar-day';
import CalendarDayHoliday from '../employee-calendar/calendar-day-holiday';

import Card from 'react-bootstrap/Card';
import {isHolidayOrWeekend} from '../../features/holidays';
import CustomerIcon from '../employee-calendar/customer-icon';

//import {fetchUsers, selectUsers} from '../../features/users';

// import Card from 'react-bootstrap/Card';
// import {selectSettings} from '../../features/settings';

const JobCalendarLine = (props) => {
  const job = props.job;

  const settings = useSelector(selectSettings);

  const startDate = settings.date;
  const days = settings.displayDurationWeeks * 7;

  const holidays = useSelector(state => {
    return state.holidays.items;
  });

  const dayItems = [];

  dayItems.push(<CalendarDay key={-1} date={new Date('1970-01-01')} job={job} showHoursCount={false}/>);

  for (let i = 0; i < days; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    const isHoliday = isHolidayOrWeekend(holidays, date);

    if (isHoliday) {
      dayItems.push(<CalendarDayHoliday key={i} />);
    } else {
      dayItems.push(<CalendarDay key={i} date={date} job={job} showHoursCount={false}/>);
    }
  }

  return (
      <div className="card-group">

         <Card style={{width: settings.cardWidth + 'px'}}>
           <div className="m-2">
           <strong>{job.description}</strong>
             <p className="small">{job.title}</p>
             <CustomerIcon customerUid={job.customer} />
           </div>
         </Card>

       {dayItems}
     </div>
  );
};

export default JobCalendarLine;
