import {Button, ButtonGroup, Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {Save as SaveIcon, Check as CheckIcon} from 'react-bootstrap-icons';
import {updateJob, removeJob, fetchJobs} from '../../features/jobs';
import {fetchCustomers, selectCustomers, selectCustomerByUid} from '../../features/customers';

const JobListItem = (props) => {
  const dispatch = useDispatch();

  const [job, setJob] = useState(props.job);

  const customers = useSelector(state => {
    return selectCustomers(state);
  });

  const customerOptions = [];
  if (customers) {
    customers.forEach((customer) => {
      customerOptions.push(
          {value: customer.uid, label: customer.title});
    });
  }

  const activeCustomer = useSelector(state => {
    return selectCustomerByUid(state, job.customer);
  });

  const [isEdit, setEdit] = useState(() => {
    return (job.title === '') || false;
  });

  const toggleEdit = () => {
    setEdit(!isEdit);
  };

  const save = async () => {
    try {
      await dispatch(updateJob(job));
      setEdit(false);
    } catch (err) {

    } finally {

    }
  };

  const remove = async () => {
    try {
      await dispatch(removeJob(job));
    } catch (err) {
    }
  };

  const reset = () => {
    setJob(props.job);
    setEdit(false);
  };

  const customersStatus = useSelector(
      state => { return state.customers.status; });

  useEffect(() => {
    setJob(props.job);

    if (customersStatus === 'idle') {
      dispatch(fetchCustomers());
    }
  }, [props.job, customersStatus]);

  let output;

  if (isEdit) {
    output = (
        <tr>

          <td className="align-middle">
            <Form.Control type="text" placeholder="" maxLength={8}
                          value={job.title}
                          onChange={(e) => setJob(
                              {...job, title: e.target.value})}/>
          </td>



          <td className="align-middle">
            <Select options={customerOptions} required name="job"
                    value={customerOptions.find(option => option.value === job.customer)}
                    onChange={(value) => {
                      setJob({...job, customer: value.value});
                    }}/>
          </td>

          <td className="align-middle">
            <Form.Control autoFocus type="text" placeholder=""
                          value={job.description}
                          onChange={(e) => setJob(
                              {...job, description: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Check type="switch" checked={job.active}
                        onChange={(e) => setJob(
                            {...job, active: e.target.checked})}/>
          </td>


          <td className="text-end align-middle" style={{width: '340px'}}>
            <ButtonGroup className="">
              <Button onClick={save}><SaveIcon/> speichern</Button>
              <Button onClick={remove}>löschen</Button>
              <Button onClick={reset}>abbrechen</Button>
            </ButtonGroup>
          </td>

        </tr>
    )
    ;
  } else {

    // let departmentName = '';
    // if (job.department) {
    //   departmentName = job.department.title;
    // }

    output = (
        <tr>
          <td className="align-middle px-3">{job.title}</td>
          <td className="align-middle px-3">{activeCustomer?.title}</td>
          <td className="align-middle px-3">{job.description}</td>
          <td className="align-middle px-3">
            {job.active ? <CheckIcon/> : ''}
          </td>
          <td className="align-middle text-end" style={{width: '340px'}}>
            <Button onClick={toggleEdit}>bearbeiten</Button></td>
        </tr>
    );
  }

  return output;
};

export default JobListItem;
