import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectSettings} from '../../features/settings';
import {Form, Button} from 'react-bootstrap';
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon
} from 'react-bootstrap-icons';

const CalendarDateSelector = (props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);

  const [date, setDate] = useState(settings.date);
  const [displayDurationWeeks, setDisplayDurationWeeks] = useState(
      settings.displayDurationWeeks);

  const weeks = [];
  for (let i = 1; i <= 16; i++) {
    const weekItem = {value: i, label: `${i} Wochen`};
    if (i === 1) {
      weekItem.label = `${i} Woche`;
    }
    weeks.push(weekItem);
  }

  const onChangeDate = (e) => {

    const date = new Date(e.target.value);
    if (isNaN(date.getTime())) {
      return;
    }

    setDate(e.target.value);
    dispatch(
        {type: 'settings/set', payload: {name: 'date', value: e.target.value}});
  };

  const onChangeWeeks = (e) => {
    setDisplayDurationWeeks(e.target.value);
    dispatch({
      type: 'settings/set',
      payload: {name: 'displayDurationWeeks', value: e.target.value}
    });
  };

  const minusOneWeek = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 7);
    setDate(newDate.toISOString().split('T')[0]);
    dispatch({
      type: 'settings/set',
      payload: {name: 'date', value: newDate.toISOString().split('T')[0]}
    });
  };

  const plusOneWeek = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);
    setDate(newDate.toISOString().split('T')[0]);
    dispatch({
      type: 'settings/set',
      payload: {name: 'date', value: newDate.toISOString().split('T')[0]}
    });
  };

  return (
      <div className="d-flex">

        <Button variant="outline-secondary"
                onClick={minusOneWeek}><ArrowLeftIcon /></Button>
        <Button variant="outline-secondary mx-2"
                onClick={plusOneWeek}><ArrowRightIcon/></Button>

        <Form.Control
            className="me-2"
            type="date"
            id="dateSelector"
            value={date}
            onChange={onChangeDate}
            title="Datum wählen"/>

        <Form.Select aria-label="Zeitraum auswählen" onChange={onChangeWeeks}
                     value={displayDurationWeeks}>
          {weeks &&
              weeks.map((week, index) => (
                  <option key={index} value={week.value}>{week.label}</option>
              ))
          }
        </Form.Select>
      </div>
  );
};

export default CalendarDateSelector;
