import { Outlet } from "react-router-dom";

import Header from '../components/page/header/header';

const Layout = () => {

  return (
      <>

        <Header>

        </Header>

        <div style={{paddingTop: '100px'}}>
          <Outlet />
        </div>
      </>
  )
};

export default Layout;
