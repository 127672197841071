import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

const getAllDepartments = () => {
  return http.get("/department/all");
}

// const getDepartment = (id) => {
//   return http.get(`/department/${id}`);
// }

export const fetchDepartments = createAsyncThunk(
    'departments/FetchDepartments',
    async () => {
      const result = await getAllDepartments();
      return result.data;
    }
);

const departmentsSlice = createSlice({
  name: 'departments',
  initialState: {
    items: [],
    status: 'idle',
    error: null
  },
  reducers: {
    resetStatus: (state, action) => {
      state.status = 'idle';
    }
  },
  extraReducers(builder) {
    builder
        .addCase(fetchDepartments.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchDepartments.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchDepartments.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
  }
});

export default departmentsSlice.reducer;

export const selectDepartments = (state) => {
  return state.departments.items;
}

export const selectDepartmentsAlphabetical = (state) => {

  // if (!state.departments) {
  //   return [];
  // }


  let departments = [...state.departments.items];

  if (Array.isArray(departments)) {
    return departments.sort((a, b) => a.title.localeCompare(b.title));
  } else {
    return [];
  }
};
