import {Button, ButtonGroup, Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Save as SaveIcon, Check as CheckIcon} from 'react-bootstrap-icons';
import {updateCustomer, removeCustomer} from '../../features/customers';

const CustomerListItem = (props) => {
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState(props.customer);

//  let departmentUid = '';
  // if (customer.department) {
  //   departmentUid = customer.department.uid;
  // }

  const [isEdit, setEdit] = useState(() => {
    return (customer.title === '') || false;
  });

  const toggleEdit = () => {
    setEdit(!isEdit);
  };

  const save = async () => {
    try {
      await dispatch(updateCustomer(customer));
      setEdit(false);
    } catch (err) {

    } finally {

    }
  };

  const remove = async () => {
    try {
      await dispatch(removeCustomer(customer));
    } catch (err) {

    }

  };

  const reset = () => {
    setCustomer(props.customer);
    setEdit(false);
  };

  useEffect(() => {
    setCustomer(props.customer);
  }, [props.customer]);

  let output;

  if (isEdit) {
    output = (
        <tr>
          <td style={{width: '80px'}} className="align-middle">
            <Form.Control
                type="color"
                id="exampleColorInput"
                value={customer.color}
                onChange={(e) => setCustomer(
                    {...customer, color: e.target.value})}
                title="Farbe auswählen" style={{
              width: '40px',
              height: '40px',
              padding: 0,
              border: 'none'
            }}/>
          </td>

          <td className="align-middle">
            <Form.Control type="text" placeholder="" maxLength={8}
                          value={customer.publicId}
                          onChange={(e) => setCustomer(
                              {...customer, publicId: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Control autoFocus type="text" placeholder=""
                          value={customer.title}
                          onChange={(e) => setCustomer(
                              {...customer, title: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Control type="text" placeholder="" maxLength={4}
                          value={customer.shortTitle}
                          onChange={(e) => setCustomer(
                              {...customer, shortTitle: e.target.value})}/>
          </td>

          <td className="align-middle">
            <Form.Check type="switch" checked={customer.active}
                        onChange={(e) => setCustomer(
                            {...customer, active: e.target.checked})}/>
          </td>


          <td className="text-end align-middle" style={{width: '340px'}}>
            <ButtonGroup className="">
              <Button onClick={save}><SaveIcon/> speichern</Button>
              <Button onClick={remove}>löschen</Button>
              <Button onClick={reset}>abbrechen</Button>
            </ButtonGroup>
          </td>

        </tr>
    )
    ;
  } else {

    // let departmentName = '';
    // if (customer.department) {
    //   departmentName = customer.department.title;
    // }

    output = (
        <tr>
          <td style={{width: '80px'}} className="align-middle">
            <div className="rounded" style={{
              width: '40px',
              height: '40px',
              backgroundColor: customer.color
            }}></div>
          </td>
          <td className="align-middle px-3">{customer.publicId}</td>
          <td className="align-middle px-3">{customer.title}</td>
          <td className="align-middle px-3">{customer.shortTitle}</td>
          <td className="align-middle px-3">

            {customer.active ? <CheckIcon/> : ''}
          </td>
          <td className="align-middle text-end" style={{width: '340px'}}>
            <Button onClick={toggleEdit}>bearbeiten</Button></td>
        </tr>
    );
  }

  return output;
};

export default CustomerListItem;
