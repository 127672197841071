import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HolidayList from '../../components/holiday/holiday-list';

const HolidayListPage = () => {
  return (
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3">Feiertage</h1>
            <HolidayList />
          </Col>
        </Row>
      </Container>
  );
};

export default HolidayListPage;
