import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  fetchDepartments
} from '../../features/departments';

import {
  fetchTasks
} from '../../features/tasks';

import {
  fetchHolidays
} from '../../features/holidays';

import DepartmentSection from './department-section';
import CalendarDateSelector from './calendar-date-selector';
import CalendarDateLine from './calendar-date-line';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {selectSettings} from '../../features/settings';
import {fetchCustomers} from '../../features/customers';

const EmployeeCalendar = () => {
  const dispatch = useDispatch();

  const departments = useSelector(state => {
    return state.departments.items;
  });

  const departmentsStatus = useSelector(
      state => { return state.departments.status; });

  const tasksStatus = useSelector(
      state => { return state.tasks.status; });

  const holidaysStatus = useSelector(
      state => { return state.holidays.status; });

  const customersStatus = useSelector(
      state => { return state.customers.status; });

  const settings = useSelector(selectSettings);

  const days = settings.displayDurationWeeks * 7;

  const refDateLine = useRef(null);
  const refMain = useRef(null);

  const onScrollDateLine = (e) => {
    refMain.current.scrollLeft = refDateLine.current.scrollLeft;
  };

  const onScrollMain = (e) => {
    refDateLine.current.scrollLeft = refMain.current.scrollLeft;
  };

  useEffect(() => {
    if (departmentsStatus === 'idle') {
      dispatch(fetchDepartments());
    }

    if (tasksStatus === 'idle') {
      dispatch(fetchTasks());
    }

    if (holidaysStatus === 'idle') {
      dispatch(fetchHolidays());
    }

    if (customersStatus === 'idle') {
      dispatch(fetchCustomers());
    }
  }, []);

  // [departmentsStatus, tasksStatus, holidaysStatus, customersStatus, dispatch]

  return (
      <div style={{paddingTop: '43px'}}>

        <div style={{position: 'fixed', top: '73px', width: '100%', zIndex: 200, background: 'white'}}>
        <Container>
          <Row>
            <Col className="my-3">
              <CalendarDateSelector/>
            </Col>
          </Row>
        </Container>
        </div>

        <div style={{position: 'relative', top: '0', background: 'white', width: '100%'}}>

          <div className="shadow" ref={refDateLine} onScroll={onScrollDateLine} style={{
            position: 'fixed',
            zIndex: 100,
            width: '100%',
            overflow: 'hidden',
            backgroundColor: 'white'
          }}>
            <div style={{
              position: 'relative',
              width: (days * settings.cardWidth) + 'px'
            }}>
              <CalendarDateLine/>
            </div>
          </div>


          <div ref={refMain} onScroll={onScrollMain}
               style={{width: '100%', overflowY: 'scroll', top: '50px', position: 'relative'}}>

            <div style={{
              position: 'relative',
              width: (days * settings.cardWidth) + 'px'
            }}>

              {departments.map((department, index) => (
                  <DepartmentSection key={index} department={department}/>
              ))}

            </div>
          </div>
        </div>
      </div>
  );

};

export default EmployeeCalendar;
