import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

const apiGetAllHolidays = () => {
  return http.get('/holiday/all');
};

const apiCreateHoliday = (data) => {
  return http.post('/holiday', data);
};

const apiUpdateHoliday = (uid, data) => {
  return http.put(`/holiday/${uid}`, data);
};

const apiDeleteHoliday = (uid) => {
  return http.delete(`/holiday/${uid}`);
};

export const fetchHolidays = createAsyncThunk(
    'holidays/FetchHolidays',
    async (_, thunkAPI) => {
      const result = await apiGetAllHolidays();
      return result.data;
    }
);

export const addHoliday = createAsyncThunk(
    'holidays/add',
    async (holiday, thunkAPI) => {
      const response = await apiCreateHoliday(holiday);

      thunkAPI.dispatch(fetchHolidays());

      return response.data;
    }
);

export const updateHoliday = createAsyncThunk(
    'holidays/update',
    async (holiday, thunkAPI) => {
      const response = await apiUpdateHoliday(holiday.uid, holiday);
      thunkAPI.dispatch(fetchHolidays());
      return response.data;
    }
);

export const removeHoliday = createAsyncThunk(
    'holidays/remove',
    async (holiday, thunkAPI) => {
      const response = await apiDeleteHoliday(holiday.uid);
      thunkAPI.dispatch(fetchHolidays());
      return response.data;
    }
);

const sortByDate = (state, action, direction) => {

  if (!Array.isArray(state.items)) {
    return state;
  }

  if (direction === undefined) {
    direction = 'asc';
  }

  state.sortDirection = direction;

  state.itemsSorted = state.items.sort((a, b) => {
    if (direction === 'asc') {
      return new Date(a.date) - new Date(b.date);
    } else {
      return new Date(b.date) - new Date(a.date);
    }
  });
};

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState: {
    items: [],
    itemsSorted: [],
    status: 'idle',
    error: null
  },
  reducers: {
    sortByDate: (state, action) => {
      sortByDate(state, action, 'desc');
      return state;
    }
  },
  extraReducers(builder) {
    builder
        .addCase(fetchHolidays.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchHolidays.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchHolidays.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(addHoliday.fulfilled, (state, action) => {
        })
        .addCase(updateHoliday.fulfilled, (state, action) => {
        })
        .addCase(removeHoliday.fulfilled, (state, action) => {
        });
  }
});

export default holidaysSlice.reducer;

export const selectHolidays = (state) => {
  return state.holidays.items;
};

export const selectHolidaysSorted = (state) => {
  return state.holidays.itemsSorted;
};


export const isHolidayOrWeekend = (items, date) => {
  const dateObj = new Date(date);

  if (!Array.isArray(items)) {
    return false;
  }

  const holiday = items.find((holiday) => {
    return holiday.date === dateObj.toISOString().slice(0, 10)
  });

  if (holiday) {
    return true;
  }

  const day = new Date(date).getDay();

  if (day === 0 || day === 6) {
    return true;
  }

  return false;
}
