const GLOBALS = {
  SERVER: {

  },
  API: {
    CUSTOMER_ALL: '/customer/all'
  },

  ITEM_TYPES: {
    CARD: 'CARD',
  },

  HOURS_PER_DAY_WARNING_OFFSET: 1,
};

export default GLOBALS;
