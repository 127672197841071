import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

export const doLogin = createAsyncThunk(
    'feusers/login',
    async (credentials, thunkAPI) => {
      const response = await http.post('/auth', credentials);
      return response.data;
    }
);

export const doLogout = createAsyncThunk(
    'feusers/logout',
    async (data, thunkAPI) => {
//      thunkAPI.dispatch({type: 'feusers/logout'});

      const response = await http.post('/auth/logout');
      return response.data;
    }
);

export const getUserData = createAsyncThunk(
    'feusers/getUserData',
    async () => {

      const userToken = localStorage.getItem('userToken');

      if (!userToken) {
        return false;
      }

      const response = await http.get('/user');
      return response.data;
    }
);

const feusersSlice = createSlice({
  name: 'feusers',
  initialState: {
    userData: {},
    userToken: '',
    status: 'idle',
    getDataStatus: 'idle',
    error: null
  },
  reducers: {
    logout: (state) => {
      state.userData = {};
      state.userToken = '';
      state.status = 'idle';
      state.error = null;
      localStorage.removeItem('userToken');
    }
  },
  extraReducers(builder) {
    builder
        .addCase(doLogin.pending, (state, action) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(doLogin.fulfilled, (state, action) => {
          state.status = 'loggedIn';
          state.userData = action.payload;
          state.userToken = action.payload.token;
          localStorage.setItem('userToken', state.userToken);
        })
        .addCase(doLogin.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(getUserData.pending, (state, action) => {
          state.getDataStatus = 'loading';
        })
        .addCase(getUserData.fulfilled, (state, action) => {
          state.getDataStatus = 'success';
          state.userData = action.payload;

          // if (!state.userDate?.uid) {
          //   localStorage.removeItem('userToken');
          // }

          //state.getDataStatus = action.payload;
          // state.userToken = action.payload.token;
          // localStorage.setItem('userToken', state.userToken);
        })
        .addCase(getUserData.rejected, (state, action) => {
          state.getDataStatus = 'failed';
          state.error = action.error.message;
        })
  }
});

export default feusersSlice.reducer;

export const selectCurrentFeUser = (state) => {
  return state.feusers.userData;
};
