import {Outlet} from 'react-router-dom';

const LayoutNotAuthorized = () => {

  return (
      <Outlet/>
  );
};

export default LayoutNotAuthorized;
