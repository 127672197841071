import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomerList from '../../components/customer/customer-list';

const CustomerListPage = () => {
  return (
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3">Kunden</h1>
            <CustomerList />
          </Col>
        </Row>
      </Container>
  );
};

export default CustomerListPage;
