import {Form} from 'react-bootstrap';
import React, {useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchDepartments,
  selectDepartments,
  selectDepartmentsAlphabetical
} from '../../features/departments';

const DepartmentSelect = (props) => {
  const dispatch = useDispatch();

  const departments = useSelector(state => {
    return selectDepartments(state);
  });

  const departmentsStatus = useSelector(
      state => { return state.departments.status; });

  useEffect(() => {
    if (departmentsStatus === 'idle') {
      dispatch(fetchDepartments());
    }
  }, [departmentsStatus, dispatch]);

  return (
      <Form.Select aria-label="Bereich auswählen" onChange={props.onChange} value={props.value}>
        <option value="0">Bitte auswählen</option>
        {departments &&
            departments.map((department, index) => (
                <option key={department.uid} value={department.uid}>{department.title}</option>
             ))
        }
      </Form.Select>
  )
};

export default DepartmentSelect;
