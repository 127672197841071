import {useSelector, useDispatch} from 'react-redux';

import Globals from '../../constants/Globals';

import {useDrop} from 'react-dnd';

import {Button, Card} from 'react-bootstrap';

import {
  filterTasksByJobAndDate,
  selectTasks, sortTasksByDoneAndImportant
} from '../../features/tasks';
import CalendarTask from '../employee-calendar/calendar-task';
import {selectSettings} from '../../features/settings';

const CalendarDay = (props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);

  const jobId = props.job.uid;

//  const [hoursCount, setHoursCount] = useState(0);

  const dateString = props.date.toISOString().split('T')[0];

  const tasks = sortTasksByDoneAndImportant(
      filterTasksByJobAndDate(useSelector(selectTasks), jobId, dateString));

//  const effortCount = countEffortByUserAndDate(tasks, jobId, dateString);

  const taskItems = [];

  for (const task of tasks) {
    taskItems.push(<CalendarTask key={task.uid} task={task} mode="job"/>);
  }

  const allowedDropEffect = 'any';

  function addTask() {
    dispatch({
      type: 'ui/setModalState',
      payload: {
        name: 'newTask',
        value: true,
        params: {mode: 'new', date: dateString, job: jobId}
      }
    });
  }

  function selectBackgroundColor(isActive, canDrop) {
    if (isActive) {
      return 'rgba(0, 255, 0, 0.2)';
    } else if (canDrop) {
      return 'rgba(0, 255, 0, 0.1)';
    } else {
      return '#fff';
    }
  }

  const [{canDrop, isOver}, drop] = useDrop(
      () => ({
        accept: Globals.ITEM_TYPES.CARD,
        drop: () => ({
          date: dateString,
          jobId: jobId,
          allowedDropEffect
        }),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        })
      }),
      [allowedDropEffect, dateString, jobId]
  );
  const isActive = canDrop && isOver;
  const backgroundColor = selectBackgroundColor(isActive, canDrop);

  // const boxShadow = selectBoxShadow(
  //     (effortCount > user.workingHoursPerDay && props.showHoursCount));

  return (
      <Card ref={drop} style={{
        backgroundColor: backgroundColor,
        width: settings.cardWidth + 'px',
        minHeight: '120px',
        paddingBottom: '60px',
        // boxShadow: boxShadow
      }}>
        {taskItems}

        <Button variant="outline-primary" style={{
          position: 'absolute',
          right: '1rem',
          bottom: '1rem',
          width: '40px'
        }} onClick={addTask}>+</Button>
      </Card>
  );
};

export default CalendarDay;
