import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchJobs, selectJobsSorted} from '../../features/jobs';

import Table from 'react-bootstrap/Table';

import JobListItem from './list-item';
import CreateJob from './create-job';

const JobList = () => {
  const dispatch = useDispatch();

  const jobs = useSelector(state => {
    return selectJobsSorted(state);
  });

  const jobsStatus = useSelector(
      state => { return state.jobs.status; });

  useEffect(() => {
    if (jobsStatus === 'idle') {
      dispatch(fetchJobs());
    }
    dispatch({type: 'jobs/sortByName'});
  }, [jobsStatus, dispatch]);

  return (
      <div>

        <CreateJob/>

        <Table striped bordered hover>
          <thead>
          <tr>
            <th className="col-1">Jobnummer</th>
            <th className="col-3">Kunde</th>
            <th className="col-3">Beschreibung</th>
            <th className="col-1">Aktiv</th>
            <th className="col-3"></th>
          </tr>
          </thead>
          <tbody>
          {jobs &&
              jobs.map((job, index) => (
                  <JobListItem key={job.uid} job={job}/>
              ))
          }
          </tbody>
        </Table>
      </div>
  );
};

export default JobList;
