import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchHolidays, selectHolidaysSorted} from '../../features/holidays';

import Table from 'react-bootstrap/Table';

import HolidayListItem from './list-item';
import CreateHoliday from './create-holiday';

const HolidayList = () => {
  const dispatch = useDispatch();

  const holidays = useSelector(state => {
    return selectHolidaysSorted(state);
  });

  const holidaysStatus = useSelector(
      state => { return state.holidays.status; });

  useEffect(() => {
    if (holidaysStatus === 'idle') {
      dispatch(fetchHolidays());
    }
    dispatch({type: 'holidays/sortByDate'});
  }, [holidaysStatus, dispatch]);

  return (
      <div>

        <CreateHoliday/>

        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Name</th>
            <th className="col-2">Datum</th>
            <th className="col-3"></th>
          </tr>
          </thead>
          <tbody>
          {holidays &&
              holidays.map((holiday, index) => (
                  <HolidayListItem key={holiday.uid} holiday={holiday}/>
              ))
          }
          </tbody>
        </Table>
      </div>
  );
};

export default HolidayList;
