import {useSelector} from 'react-redux';
import {selectUserById} from '../../features/users';

const UserIcon = (props) => {
  const user = useSelector(
      state => selectUserById(state, props.userUid));

  const backgroundColor = user?.color ?? '#000';

  let fontColor = '#fff';
  /* invert fontColor if backgroundColor is too dark */
  if (backgroundColor.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
    const c = backgroundColor.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 200) {
      fontColor = '#fff';
    } else {
      fontColor = '#000';
    }
  }

  return (
      <div className="customer-icon border rounded" style={{...{
        width: '30px',
        height: '30px',
        color: fontColor,
        fontSize: '10px',
        lineHeight: '30px',
        textAlign: 'center',
        backgroundColor: backgroundColor
      }, ...props.style}}>
        {user?.shortName}
      </div>
  );
};

export default UserIcon;
