import {useSelector} from 'react-redux';
import {Card} from 'react-bootstrap';
import {EmojiSunglasses as EmojiSunglassesIcon} from 'react-bootstrap-icons';
import {selectSettings} from '../../features/settings';

const CalendarDayHoliday = (props) => {

  const settings = useSelector(selectSettings);

  return (
      <Card style={{backgroundColor: 'var(--bs-card-cap-bg)', width: settings.cardWidth + 'px', opacity: .5}}>
        <div className="d-flex m-0 align-items-center justify-content-center" style={{height: '100%', fontSize: '4rem', color: 'var(--bs-gray-300'}}>
          <EmojiSunglassesIcon/>

        </div>
      </Card>
  )
}

export default CalendarDayHoliday;
