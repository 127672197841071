import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDrag} from 'react-dnd';

import Card from 'react-bootstrap/Card';
import {CheckCircle as CheckCircleIcon} from 'react-bootstrap-icons';

import Globals from '../../constants/Globals';
import {copyTask} from '../../features/tasks';
import {selectJobById} from '../../features/jobs';

import CustomerIcon from './customer-icon';
import UserIcon from './user-icon';
import {
  fetchUsers,
  selectUserById
} from '../../features/users';

const CalendarTask = ({isDragging, task, mode}) => {
//  const task = props.task;
  const dispatch = useDispatch();

  const job = useSelector(state => {
    return selectJobById(state, task.job);
  });

  const user = useSelector(state => {
    return selectUserById(state, task.user);
  });

  const usersStatus = useSelector(
      state => {return state.users.status;});

  const users = useSelector(state => {
    return state.users.items;
  });

  useEffect(() => {
    if (usersStatus === 'idle' && users.length === 0) {
      dispatch(fetchUsers());
    }
    // eslint-disable-next-line
  }, [usersStatus]);

  const [{opacity}, dragRef] = useDrag(
      () => ({
        type: Globals.ITEM_TYPES.CARD,
        item: {task},
        end: (item, monitor) => {

//          console.log('item', item);

          const dropResult = monitor.getDropResult();

//          console.log('dropResult', dropResult);

          if (item && dropResult) {

            // const userId = item.task.user;
            // const jobId = item.task.job;

//            console.log('item', item);

            switch (dropResult.dropEffect) {
              case 'copy':
                const newTask = {...task};
                delete (newTask.uid);
                newTask.date = dropResult.date;

                if (dropResult?.userId) {
                  newTask.user = dropResult?.userId;
                }

                if (dropResult?.jobId) {
                  newTask.job = dropResult?.jobId;
                }

                dispatch(copyTask(newTask));
                break;
              case 'move':
                dispatch({
                  type: 'tasks/move',
                  payload: {
                    task: task,
                    date: dropResult.date,
                    userId: dropResult?.userId,
                    jobId: dropResult?.jobId,
                  }
                });
                break;
              default:
                console.log('unknown dropEffect', dropResult.dropEffect);
            }

            //dispatch(addUser(user));

//            console.log(`You dropped ${item.name} into ${dropResult.name}!`)
          }
        },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.5 : 1
        })
      }),
      [task]
  );

  // const allowedDropEffect = 'any';
  //
  // const [{ canDrop, isOver }, drop] = useDrop(
  //     () => ({
  //       accept: Globals.ITEM_TYPES.CARD,
  //       drop: () => ({
  //         task: task,
  //         test: 'test',
  //         allowedDropEffect,
  //       }),
  //       collect: (monitor) => ({
  //         isOver: monitor.isOver(),
  //         canDrop: monitor.canDrop(),
  //       }),
  //     }),
  //     [allowedDropEffect, task],
  // )

  function editTask() {
    dispatch({
      type: 'ui/setModalState',
      payload: {
        name: 'newTask',
        value: true,
        params: {mode: 'edit', taskUid: task.uid}
      }
    });
  }

  let borderColor = '';
  if (!task.done) {
    switch (task.important) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        borderColor = 'orange';
        break;
      case 3:
        borderColor = 'red';
        break;
      default:
        break;
    }
  }

  let opacityValue;

  if (task.done) {
    opacityValue = 0.6;
  } else {
    opacityValue = opacity;
  }

  return (
      <Card className="m-2 p-2" style={{
        position: 'relative',
        minHeight: '90px',
        borderColor: borderColor,
        opacity: opacityValue
      }} ref={dragRef} onClick={editTask}>
        <div className="me-4">
          <strong>{task.done
              ? <CheckCircleIcon style={{color: 'green'}}/>
              : ''} {task.teaser}</strong>
          <p className="small">
            {mode === 'employee' && job?.description}
            {mode === 'job' && user?.name}
          </p>
        </div>

        <div className="border rounded px-1" style={{
          position: 'absolute',
          right: '.5rem',
          top: '.5rem',
          width: 'auto',
          minWidth: '30px',
          height: '30px',
          fontSize: '10px',
          lineHeight: '30px',
          textAlign: 'center'
        }}>
          {task.effort} h
        </div>

        {mode === 'job' && <UserIcon userUid={task.user} style={{
          position: 'absolute',
          right: '.5rem',
          bottom: '.5rem'
        }}/>}
        {mode === 'employee' && <CustomerIcon customerUid={job?.customer} style={{
          position: 'absolute',
          right: '.5rem',
          bottom: '.5rem',
        }}/>}
      </Card>
  );
};

export default CalendarTask;
