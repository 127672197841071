import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import Layout from './pages/Layout';
import LayoutNotAuthorized from './pages/LayoutNotAuthorized';
import LoginPage from './pages/Login/Login';
import LoadingPage from './pages/Loading';
import CustomerList from './pages/Customer/List';
import JobCalendarPage from './pages/JobCalendar/JobCalendar';
import EmployeeCalendarPage from './pages/EmployeeCalendar/EmployeeCalendar';
import UserListPage from './pages/User/List';
import JobListPage from './pages/Job/List';
import HolidayListPage from './pages/Holiday/List';

import {getUserData, selectCurrentFeUser} from './features/feusers';

const App = (state) => {

  const dispatch = useDispatch();

  const currentFeUser = useSelector(state => {
    return selectCurrentFeUser(state);
  });

  // const feUserStatus = useSelector(
  //     state => { return state.feusers.status; });

  const feUserGetDataStatus = useSelector(
      state => { return state.feusers.getDataStatus; });

//  const [currentFeUser, setCurrentUser] = useState({});
//  const currentFeUser = useSelector(selectCurrentFeUser);

  useEffect(() => {
    if (feUserGetDataStatus === 'idle') {
      dispatch(getUserData());
    }
  }, [dispatch, feUserGetDataStatus]);

  let routeElement;

  if (['loading', 'idle'].includes(feUserGetDataStatus)) {
    routeElement =
        <Route path="/" element={<LayoutNotAuthorized/>}>
          <Route index element={<LoadingPage/>}/>
        </Route>;
  } else {
    if (currentFeUser?.uid) {
      routeElement =
          <Route path="/" element={<Layout/>}>
            <Route path="/" element={<Navigate to="employeeCalendar" replace/>}/>
            <Route path="employeeCalendar"
                   element={<EmployeeCalendarPage/>}/>
            <Route path="jobCalendar"
                   element={<JobCalendarPage/>}/>
            <Route path="customerList" element={<CustomerList/>}/>
            <Route path="userList" element={<UserListPage/>}/>
            <Route path="jobList" element={<JobListPage/>}/>
            <Route path="holidayList" element={<HolidayListPage/>}/>
          </Route>;
    } else {
      routeElement =
          <Route path="/" element={<LayoutNotAuthorized/>}>
            <Route index element={<LoginPage/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
          </Route>;
    }

  }

  return (
      <BrowserRouter>
        <Routes>
          {routeElement}
        </Routes>
      </BrowserRouter>
  );
};

export default App;
