import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {createAxiosClient} from '../http-common';
const http = createAxiosClient();

const apiGetAllCustomers = () => {
  return http.get('/customer/all');
};

const apiCreateCustomer = (data) => {
  return http.post('/customer', data);
};

const apiUpdateCustomer = (uid, data) => {
  return http.put(`/customer/${uid}`, data);
};

const apiDeleteCustomer = (uid) => {
  return http.delete(`/customer/${uid}`);
};

export const fetchCustomers = createAsyncThunk(
    'customers/FetchCustomers',
    async (_, thunkAPI) => {
      const result = await apiGetAllCustomers();
      return result.data;
    }
);

export const addCustomer = createAsyncThunk(
    'customers/add',
    async (customer, thunkAPI) => {
      const response = await apiCreateCustomer(customer);

      thunkAPI.dispatch(fetchCustomers());

      return response.data;
    }
);

export const updateCustomer = createAsyncThunk(
    'customers/update',
    async (customer, thunkAPI) => {
      const response = await apiUpdateCustomer(customer.uid, customer);
      thunkAPI.dispatch(fetchCustomers());
      return response.data;
    }
);

export const removeCustomer = createAsyncThunk(
    'customers/remove',
    async (customer, thunkAPI) => {
      const response = await apiDeleteCustomer(customer.uid);
      thunkAPI.dispatch(fetchCustomers());
      return response.data;
    }
);

const sortByName = (state, action) => {
  if (!Array.isArray(state.items)) {
    return state;
  }

  state.itemsSorted = state.items.toSorted((a, b) => {
    return a.title.localeCompare(b.title);
  });
}

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    items: [],
    itemsSorted: [],
    status: 'idle',
    error: null
  },
  reducers: {
    sortByName: (state, action) => {
      sortByName(state, action);
      return state;
    },
  },
  extraReducers(builder) {
    builder
        .addCase(fetchCustomers.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchCustomers.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchCustomers.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(addCustomer.fulfilled, (state, action) => {
        })
        .addCase(updateCustomer.fulfilled, (state, action) => {
        })
        .addCase(removeCustomer.fulfilled, (state, action) => {
        });
  }
});

export default customersSlice.reducer;

export const selectCustomers = (state) => {
  return state.customers.items;
};

export const selectCustomersSorted = (state) => {
  return state.customers.itemsSorted;
};

export const selectCustomerByUid = (state, uid) => {
  return state.customers.items.find((customer) => {
    return customer.uid === uid;
  });
}

export const sortCustomersByTitle = (customers, direction) => {

  const resultCustomers = [...customers];

  resultCustomers.sort((a, b) => {
    if (direction === 'desc') {
      return b.title.localeCompare(a.title);
    } else {
      return a.title.localeCompare(b.title);
    }
  });

  return resultCustomers;
}
