import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import JobList from '../../components/job/job-list';

const JobListPage = () => {
  return (
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3">Jobs</h1>
            <JobList />
          </Col>
        </Row>
      </Container>
  );
};

export default JobListPage;
